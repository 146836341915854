import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "./invoice.css";
import * as path from "./../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { BASE_URL } from "../Const/Url";
import { useForm } from "react-hook-form";
import Loader from "../CustomCommons/Loader";
import swal from "sweetalert";

const EditChalanComponent = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [existingOutlet, setExistingOutlet] = useState(null);
  const [existingOutletProducts, setExistingOutletProducts] = useState([]);
  const [finalOutletVariants, setFinalOutletVariants] = useState([]);
  const [note, setNote] = useState("");
  const [status, setStatus] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useForm();

  useEffect(() => {
    const getChalanProducts = (id) => {
      const url = `${BASE_URL}api/v1/sales/admin/chalan/${id}/outlet_products/`;
      axios.get(url).then((response) => {
        if (response.data.status === "success") {
          const products = response.data.data;
          setExistingOutletProducts(products);
          setStatus(products[0]?.status);

          // Flatten all variants from all products into a single array
          const allVariants = products.reduce((acc, product) => {
            return [...acc, ...product.outletVariant];
          }, []);

          setFinalOutletVariants(allVariants);
          setExistingOutlet({
            value: products[0]?.outlet,
            label: products[0]?.outlet_name,
          });
          setLoading(false);
        }
      });
    };
    getChalanProducts(id);
  }, [id]);

  const VariantThead = () => {
    return (
      <thead style={{ border: "1.5px solid #E0E0E0" }}>
        <tr className="px-3" style={{ height: "45px" }}>
          <th
            className="minWidthForInvoiceCreateTable ps-4"
            style={{ width: "349px", textAlign: "start" }}
          >
            Variant
          </th>
          <th
            className="minWidthForInvoiceCreateTable py-3"
            style={{ width: "150px", textAlign: "center" }}
          >
            Current Main Stock
          </th>
          <th
            className="minWidthForInvoiceCreateTable py-3"
            style={{ width: "150px", textAlign: "center" }}
          >
            Current Outlet Stock
          </th>
        </tr>
      </thead>
    );
  };

  const saveProduct = (data) => {
    setIsSaving(true);
    const updatedProducts = existingOutletProducts.map((product) => {
      const updatedVariants = product.outletVariant.map((variant) => {
        const newStock = parseInt(data[variant.id.toString()] || variant.stock);
        return {
          ...variant,
          stock: newStock,
          admin_stock: newStock,
        };
      });

      return {
        ...product,
        outletVariant: updatedVariants,
        stock: updatedVariants.reduce((sum, v) => sum + v.stock, 0),
        admin_stock: updatedVariants.reduce((sum, v) => sum + v.stock, 0),
      };
    });

    const payload = {
      products: updatedProducts.map((product) => ({
        id: product.id,
        outletVariant: product.outletVariant.map(({ stock, variant }) => ({
          stock,
          admin_stock: stock,
          variant,
          outlet: existingOutlet.value,
        })),
        stock: product.stock,
        admin_stock: product.admin_stock,
        product: product.product,
        outlet: existingOutlet.value,
      })),
    };

    axios
      .patch(
        `${BASE_URL}/api/v1/sales/admin/chalan/${id}/outlet_products_modify/`,
        payload
      )
      .then((res) => {
        if (res.data.status === "success") {
          showToast("success", "Updated successfully");
          history.push("/outlet/chalan_list");
          setIsSaving(false);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
        setIsSaving(false);
      });
  };

  if (loading) {
    return <Loader />;
  }
  console.log(existingOutletProducts, "existingOutletProducts");

  return (
    <div className="container-fluid">
      {status === 1 && (
        <div className="alert alert-info mb-3">
          <span>This chalan has already been accepted</span>
        </div>
      )}
      {status !== 0 && status !== 1 && (
        <div className="alert alert-danger mb-3">
          <span>This chalan has been rejected</span>
        </div>
      )}
      <form onSubmit={handleSubmit(saveProduct)}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {existingOutletProducts.map((product, index) => (
                  <div key={product.id} className="mb-4">
                    <h5 className="mb-3">{product.product_details.name}</h5>
                    <table className="table table-centered table-bordered">
                      <VariantThead />
                      <tbody>
                        {product.outletVariant.map((variant) => (
                          <tr key={variant.id}>
                            <td className="ps-4">
                              {variant.variant_details.name}
                            </td>
                            <td className="text-center">
                              {variant.variant_details.stock}
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control text-center"
                                min={0}
                                max={
                                  variant.variant_details.stock + variant.stock
                                }
                                defaultValue={variant.stock}
                                disabled={status !== 0}
                                {...register(`${variant.id}`)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row bg-white py-3 my-4">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <>
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    disabled={isSaving}
                  >
                    {isSaving ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={() => history.push("/outlet/chalan_list")}
                  >
                    Cancel
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditChalanComponent;
