export const getSubTotal = (mainState, getAllVariantNameFn) => {
  // const mainState = {
  //   "Jamdani Printed Lounge Pillow [[sp0001]]": {
  //     "id": 1097,
  //     "variant": [
  //       {
  //         "id": 2354,
  //         "name": "green",
  //         "price": 0,
  //         "stock": 20,
  //         "quantity": -1,
  //         "total": 0
  //       },
  //       {
  //         "id": 2353,
  //         "name": "blue",
  //         "price": 0,
  //         "stock": 18,
  //         "quantity": "2",
  //         "total": 1180
  //       },
  //       {
  //         "id": 2352,
  //         "name": "red",
  //         "price": 100,
  //         "stock": 20,
  //         "quantity": -1,
  //         "total": 0
  //       }
  //     ],
  //     "variantObj": {
  //       "green": {
  //         "id": 2354,
  //         "name": "green",
  //         "price": 0,
  //         "stock": 20,
  //         "quantity": -1,
  //         "total": 0
  //       },
  //       "blue": {
  //         "id": 2353,
  //         "name": "blue",
  //         "price": 0,
  //         "stock": 18,
  //         "quantity": "2",
  //         "total": 1180
  //       },
  //       "red": {
  //         "id": 2352,
  //         "name": "red",
  //         "price": 100,
  //         "stock": 20,
  //         "quantity": -1,
  //         "total": 0
  //       }
  //     },
  //     "price": 590,
  //     "stock": 58,
  //     "thumb": "https://kaaruj.backend.clients.devsstream.com/media/default/placeholder.jpg",
  //     "discount": 0
  //   }
  // }
  // const mainInnerState = {
  //   "Jamdani Printed Lounge Pillow [[sp0001]]": [
  //     {
  //       "label": "blue",
  //       "value": 2353,
  //       "price": 0,
  //       "stock": 18,
  //       "quantity": 1
  //     }
  //   ]
  // }
  // const getAllVariantName = () => {
  //   var res =
  //     mainInnerState !== undefined &&
  //     [].concat(
  //       ...Object.values(mainInnerState).map((a) => a.map((b) => b.label))
  //     );
  //   return res;
  // };
  if (mainState !== undefined) {
    const allarray = Object.values(mainState)?.map(
      (curr) =>
        curr.variant !== undefined &&
        curr.variant
          .map((variants) =>
            getAllVariantNameFn().includes(variants.name)
              ? parseInt(variants.total)
              : 0
          )
          .reduce((a, b) => a + b, 0)
    );

    var sum = 0;
    for (let i in allarray) {
      sum += parseInt(allarray[i]);
    }
    return sum;
  }
};
export const getPercentageDiscount = (discount, getSubTotalFn) => {
  let total = getSubTotalFn;
  return Math.round((discount / 100) * total);
};
export const getGrandTotal = (discount, discountType, flatDiscountFn, percentDiscountFn, subTotalFn) => {
  const discountCalc =
    discountType === 0 || discountType === "0"
      ? flatDiscountFn()
      : percentDiscountFn(discount, subTotalFn);
  const charge = 0;
  const grand = subTotalFn + charge - discountCalc;
  return grand < 0 ? 0 : parseInt(grand);
};
export const getDueAmount = (grandTotal, paid) => {
  const due = grandTotal - paid;
  return due < 0 ? 0 : due;
};
