import React from "react";

const ConflictPD = ({products}) => {
  return (
    <div className="categorey-parent ">
      <div className="row me-2 me-md-0">
        {/* ======content div========== */}
        <h4 className="text-center">Conflict Product</h4>
        <div
          className="row flex justify-content-center mt-3 product-content  bg-white"
          style={{
            border: "0.5px solid #E0E0E0",
            // display: "flex",
            // flexWrap: "wrap",
          }}
        >
          {
            products.map((product) => {
                return (
                  <div
                    className="col-lg-3 gap-2 col-1 col-md-2 m-2 p-2 border"
                    key={product.id}
                    style={{ height: "130px"}}
                  >
                    <h6><span style={{color:'red',fontWeight:'bold'}}>Product Name :</span> {product?.name} </h6>
                    <h6> <span style={{color:'red',fontWeight:'bold'}}>Product SKU :</span>  {product.sku}</h6>
                    <h6><span style={{color:'red',fontWeight:'bold'}}>Previous Offer:</span>   {product.offer}</h6>
                  </div>
                );
  
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ConflictPD;
