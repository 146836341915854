import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { logout_func2 } from "../Const/logoutCommon";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { useEffect } from "react";
import Loader from "../CustomCommons/Loader";
import { Form, InputGroup } from "react-bootstrap";

const ViewOffer = () => {
  const history = useHistory();
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const { id } = useParams();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [offer, setOffer] = useState({});
  const [productDetails, setProductDetails] = useState([]);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const [count, setCount] = useState(0);
  const getOffer = () => {
    const url = `${BASE_URL}api/v1/promotions/admin/offer/${id}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOffer(result);
        // setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const getProductsDetails = (url, setLoading) => {
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setProductDetails(result.results);
        setNext(result.next);
        setPrev(result.previous);
        setCount(result.count);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const loadMoreProducts = () => {
    if (next) {
      setLoading2(true); // Set loading state to true
      axios
        .get(next) // Fetch next products
        .then((res) => {
          const result = res.data.data;
          setProductDetails((prevDetails) => [
            ...prevDetails,
            ...result.results,
          ]); // Combine previous and new data
          setNext(result.next); // Update next state
          setPrev(result.previous); // Update previous state
          setCount(result.count); // Update count if necessary
          setLoading2(false); // Set loading state to false
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          if (
            message === "Invalid token." ||
            JSON.parse(err.request.response).code === 401
          ) {
            logout_func2(setOnlogoutHide, history);
          }
          setLoading2(false); // Ensure loading state is reset on error
        });
    }
  };
  useEffect(() => {
    getOffer();
    getProductsDetails(
      `${BASE_URL}api/v1/promotions/admin/offer/${id}/all_products`,
      setLoading
    );
  }, []);
  if (loading) {
    return <Loader />;
  }
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>View Offer</h3>
          <button
            className="btn btn-danger"
            onClick={() => history.push(`/promotions/offer-history/${id}`)}
          >
            See History
          </button>
        </div>
        <div
          style={{
            padding: "20px 35px ",
            // borderBottom: " 0.5px solid #E0E0E0",
            // borderRight: " 0.5px solid #E0E0E0",
            // borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          {/* <div className="col-12 col-md-6">
            <p>Offer Start Date</p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                placeholder="Select a date"
                name="expiry_date"
                value={offer?.start_date?.split("T")[0]}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <p>Offer End Date</p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                placeholder="Select a date"
                name="expiry_date"
                value={offer?.expiry_date?.split("T")[0]}
                disabled
              />
            </InputGroup>
          </div> */}
          <div className="col-12 row justify-content-between">
            <h5 className="my-3">Offer Products</h5>
            {productDetails &&
              productDetails?.map((data) => (
                <>
                  <div key={data?.id} className="col-md-6  col-12 ">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row no-gutters">
                        <div className="col-md-4">
                          <img
                            src={data?.thumb_url}
                            className="card-img m-1 img-fluid"
                            style={{ objectFit: "contain", width: "100%" }}
                            alt="..."
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5 className="card-title">
                              {data?.name?.length > 15
                                ? `${data?.name.slice(0, 15)}...`
                                : `${data?.name}`}
                            </h5>
                            <p className="card-text">
                              Discount :{" "}
                              {offer?.discount_type === 1
                                ? `${offer?.discount_value} %`
                                : `${offer?.discount_value} Tk`}
                            </p>
                            <p className="card-text">Stocks : {data?.stock}</p>
                            <p className="card-text">
                              <small className="text-muted">
                                Last updated{" "}
                                {new Date(data.updated_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}

            {/* Load More Button */}
            <div className="d-flex justify-content-center gap-2">
              {prev && (
                <>
                  {
                    <button
                      onClick={() =>
                        getProductsDetails(
                          `${BASE_URL}api/v1/promotions/admin/offer/${id}/all_products`,
                          setLoading2
                        )
                      }
                      className="btn btn-danger"
                    >
                      Show Less
                    </button>
                  }
                </>
              )}

              {next && (
                <>
                  {loading2 ? (
                    <button disabled className="btn btn-danger">
                      Loading ...
                    </button>
                  ) : (
                    <button
                      onClick={loadMoreProducts}
                      className="btn btn-danger"
                    >
                      Show More
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOffer;
