import React, { useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import eye from "../../../assets/Icon/eye.svg";
import * as path from "./../../Routes/RoutePaths";
import { showToast } from "../../../utils/ToastHelper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AddVideo = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [sliderData, setSliderData] = useState([]);
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [vfileName, setVFileName] = useState("");
  const [Categoryswitch, setCategoryswitch] = useState(true);

  const [URL, setURL] = useState("");
  const [VURL, setVURL] = useState("");
  const [vthumbId, setVThumbId] = useState(null);
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [pageName, setPageName] = useState(1);
  const [dataType, setDataType] = useState(0);
  const [desc, setDesc] = useState("");
  // for slider
  const [sliderSwitch, setSliderSwitch] = useState(true);
  const [sliderFileName, setSliderFileName] = useState("");
  const [slideImgUrl, setSlideImgUrl] = useState("");
  const [slideImgId, setSlideImgId] = useState(null);
  const [slideTitle, setSlideTitle] = useState("");
  const [sliderDesc, setSliderDesc] = useState("");
  const [sliders, setSliders] = useState([]);
  const [sliderMobileFileName, setSliderMobileFileName] = useState("");
  const [slideMobileImgUrl, setSlideMobileImgUrl] = useState("");
  const [slideMobileImgId, setSlideMobileImgId] = useState(null);

  const SliderHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios.post(url, data1).then((res) => {
        if (res.data.status) {
          setSlideImgId(res.data.data.id);
          showToast("success", "Image Uploaded");
          setSliderFileName(e.target.files[0].name);
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = () => {
            if (reader.readyState === 2) {
              setSlideImgUrl(reader.result);
            }
          };
        }
      });
    }
  };
  // for slider
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            showToast("success", "Image Uploaded");
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
          }
        })
        .catch((error) => {
          // console.error("An error occurred:", error.response.data.errors.document[0]);
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const VideoHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      showToast("success", "large file uploading . Please wait!");
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 1);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setVThumbId(res.data.data.id);
            showToast("success", "file uploaded");
            setVFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setVURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setVURL(reader.result);
              }
            };
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          // console.error("An error occurred:", error.response.data.errors.document[0]);
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };

  console.log(
    {
      slider: sliders,
      sData: sliderData,
    },
    "slider"
  );
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeV = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        // setVThumb(null);
        setVThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeV2 = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        const sliderArray = sliders?.filter((data) => data !== id);
        const previewArray = sliderData.filter((data) => data?.id !== id);
        setSliders(sliderArray);
        setSliderData(previewArray);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const postSliderVideo = () => {
    const url = `${BASE_URL}api/v1/utility/admin/page/`;
    const data = {
      title: title,
      desc: desc,
      page_type: pageName,
      show: dataType,
      is_active: Categoryswitch,
      thumbnail: thumbId ? thumbId : null,
      video: vthumbId,
      slider: sliders,
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", " Created.");
          history.push(path.main_setting);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  const SliderMobileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios.post(url, data1).then((res) => {
        if (res.data.status) {
          setSlideMobileImgId(res.data.data.id);
          showToast("success", "Image Uploaded");
          setSliderMobileFileName(e.target.files[0].name);
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = () => {
            if (reader.readyState === 2) {
              setSlideMobileImgUrl(reader.result);
            }
          };
        }
      });
    }
  };

  const postSlider = () => {
    if (!slideImgId || !slideMobileImgId) {
      showToast("error", "Please upload both desktop and mobile images");
      return;
    }

    const url = `${BASE_URL}api/v1/utility/admin/slider/`;
    const data = {
      title: slideTitle,
      desc: sliderDesc,
      is_active: sliderSwitch,
      image: slideImgId,
      image2: slideMobileImgId,
    };

    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          setSliders([...sliders, res.data.data.id]);
          setSliderData([
            ...sliderData,
            {
              id: res.data.data.id,
              img: slideImgUrl,
              mobileImg: slideMobileImgUrl,
            },
          ]);
          showToast("success", "Slider Created.");
          setModalShow(false);
          setSlideTitle("");
          setSliderDesc("");
          setSlideImgUrl("");
          setSliderFileName("");
          setSlideImgId(null);
          setSlideMobileImgUrl("");
          setSliderMobileFileName("");
          setSlideMobileImgId(null);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };
  const handleDrag = (result) => {
    if (!result.destination) {
      return;
    }
    console.log(result, sliderData, "rrr");
    const newList = reorder(
      sliderData.slice(),
      result.source.index,
      result.destination.index
    );
    const newIdList = newList.map(({ id }) => id);
    setSliderData(newList);
    setSliders(newIdList);
    console.log(newList, "nnn");
    newList.map((item, index) => {
      return axios
        .patch(`${BASE_URL}/api/v1/auth/documents/update/${item.id}/`, {
          order: index,
        })
        .then((res) => console.log(res, "res"));
    });
  };

  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Add Video/Slider
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Title"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Select Page"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setPageName(e.target.value)}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={pageName}
              >
                <option value={1}>Home</option>
                <option value={2}>Home Decor</option>
                <option value={3}>In Style</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Description"} />
            <InputGroup className="mb-3" style={{ height: "100px" }}>
              <Form.Control
                as={"textarea"}
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Select Type"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setDataType(e.target.value)}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={dataType}
              >
                <option value={0}>Video</option>
                <option value={1}>Slider</option>
              </Form.Select>
            </div>
          </div>
          {/* for slider */}
          <div className="col-12 row"></div>

          {/* modal for slider */}
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => setModalShow(false)}
            style={{ borderRadius: "none" }}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Slider
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12 col-md-6">
                  <RequiredLabel text={"Slider Title"} />
                  <InputGroup className="mb-3" style={{ height: "47px" }}>
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="name"
                      value={slideTitle}
                      onChange={(e) => setSlideTitle(e.target?.value)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  <RequiredLabel text={"Slider Description"} />
                  <InputGroup className="mb-3" style={{ height: "47px" }}>
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="name"
                      value={sliderDesc}
                      onChange={(e) => setSliderDesc(e.target?.value)}
                    />
                  </InputGroup>
                </div>

                <div className="col-12 col-md-6 ">
                  <h5 style={{ marginTop: "30px" }}>
                    Slider image ( Desktop){" "}
                  </h5>{" "}
                  <p style={{ marginTop: "10px", color: "red" }}>
                    NB: Upload image in (height-600px, width-1920px) for Slider.{" "}
                  </p>
                  <span></span>
                  <p style={{ color: "#8E8E93" }}>
                    ( Select your file & uploaded )
                  </p>
                  <div
                    className="w-100 browse-main d-flex align-items-center"
                    style={{
                      height: "55px",
                      border: "1px solid #E0E0E0",
                      borderRadius: "7px",
                      overflowX: "hidden",
                    }}
                  >
                    {sliderFileName === "" ? (
                      <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                        No File Choosen
                      </p>
                    ) : (
                      <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                        {" "}
                        {sliderFileName}
                      </p>
                    )}

                    <div className="file-up">
                      <input
                        type="file"
                        onChange={(e) => SliderHandle(e)}
                        accept="image/*"
                      />
                      <p>Browse</p>
                    </div>
                  </div>
                  {sliderFileName && (
                    <div className="my-2">
                      <img height={90} width={90} src={slideImgUrl} alt="" />{" "}
                      <br />
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 ">
                  <h5 style={{ marginTop: "30px" }}>Slider image (Mobile)</h5>{" "}
                  <p style={{ marginTop: "10px", color: "red" }}>
                    NB: Upload image in (height-500, width-1024px) for Slider.{" "}
                  </p>
                  <span></span>
                  <p style={{ color: "#8E8E93" }}>
                    ( Select your file & uploaded )
                  </p>
                  <div
                    className="w-100 browse-main d-flex align-items-center"
                    style={{
                      height: "55px",
                      border: "1px solid #E0E0E0",
                      borderRadius: "7px",
                      overflowX: "hidden",
                    }}
                  >
                    {sliderMobileFileName === "" ? (
                      <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                        No File Choosen
                      </p>
                    ) : (
                      <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                        {" "}
                        {sliderMobileFileName}
                      </p>
                    )}

                    <div className="file-up">
                      <input
                        type="file"
                        onChange={(e) => SliderMobileHandle(e)}
                        accept="image/*"
                      />
                      <p>Browse</p>
                    </div>
                  </div>
                  {slideMobileImgUrl && (
                    <div className="my-2">
                      <img
                        height={90}
                        width={90}
                        src={slideMobileImgUrl}
                        alt=""
                      />{" "}
                      <br />
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <RequiredLabel text={"Slider Status"} />
                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={sliderSwitch}
                      onClick={() => setSliderSwitch(!sliderSwitch)}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={postSlider}>Add Slider</Button>
              <Button onClick={() => setModalShow(!true)}>Close</Button>
            </Modal.Footer>
          </Modal>
          {/* modal for slider */}
          {dataType === 1 || dataType == "1" ? (
            <>
              <div className="mt-3">
                <button
                  className="btn btn-success"
                  onClick={() => setModalShow(true)}
                >
                  Add Slider
                </button>
              </div>

              <div className="col-12 d-flex gap-1">
                <DragDropContext onDragEnd={handleDrag}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="d-flex"
                        >
                          {sliderData?.map((data, index) => (
                            <Draggable
                              key={data?.id}
                              draggableId={data.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="my-4 mx-2"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>
                                    <img
                                      height={90}
                                      width={90}
                                      src={data?.img}
                                      alt=""
                                    />
                                  </div>
                                  <button
                                    onClick={() => removeV2(data?.id)}
                                    className="remove-btn btn btn-small mt-1  rounded"
                                    style={{
                                      border: "1px solid gray !important",
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                        {provided.placeholder}
                      </>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <h5 style={{ marginTop: "30px" }}>Video </h5> <span></span>
                <p style={{ color: "#8E8E93" }}>
                  ( Select your file & uploaded )
                </p>
                <div
                  className="w-100 browse-main d-flex align-items-center"
                  style={{
                    height: "55px",
                    border: "1px solid #E0E0E0",
                    borderRadius: "7px",
                    overflowX: "hidden",
                  }}
                >
                  {vfileName === "" ? (
                    <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                      No File Choosen
                    </p>
                  ) : (
                    <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                      {" "}
                      {vfileName}
                    </p>
                  )}

                  <div className="file-up">
                    <input
                      type="file"
                      onChange={(e) => VideoHandle(e)}
                      accept="video/*"
                    />
                    <p>Browse</p>
                  </div>
                </div>
                {VURL && (
                  <div className="my-2">
                    <div>
                      <img
                        height={90}
                        width={90}
                        src={"https://i.stack.imgur.com/PtbGQ.png"}
                        alt=""
                      />
                    </div>
                    {/* <video height={90} width={90} controls>
                  <source src={VURL} />
                </video>
                <br /> */}
                    <button
                      onClick={removeV}
                      className="remove-btn btn btn-small mt-1  rounded"
                      style={{ border: "1px solid gray !important" }}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>

              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-900px, width-1900px) for Thumbnail.{" "}
              </p>
            </>
          )}

          {/* ========== */}
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-300px, width-300px) for Thumbnail.{" "}
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

            {/* ----------status--------- */}
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* save */}
          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={postSliderVideo}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.main_setting)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVideo;
