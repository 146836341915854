import React from "react";

import "./css/Loader.css";

import imageUrl1 from "./Kaaruj1.jpg";

const Loader = () => {
  return (
    <>
      {/* <div
        className="categorey-parent w-100 h-25 d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <HashLoader color="#000" />
      </div> */}
      <div className="loader-container">
        <div className="circle-loader">
          <div className="loader-border"></div>
          <img src={imageUrl1} alt="center" className="loader-image" />
        </div>
      </div>
    </>
  );
};

export default Loader;
