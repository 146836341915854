import axios from "axios";
import {
  setTokens,
  removeTokens,
  getRefreshToken,
  isTokenExpired,
} from "./tokenService";
import { BASE_URL } from "../../components/Const/Url";


export const login = async (email, password) => {
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/auth/jwtlogin/`,
      { email, password }
    );
    console.log("response.data", response.data.data);
    const { access_token: accessToken, refresh_token: refreshToken } =
      response.data.data;
    setTokens(accessToken, refreshToken);
    localStorage.setItem('userData',JSON.stringify(response.data.data))
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export const refreshToken = async () => {
  
  const refreshToken = getRefreshToken();
  if (refreshToken && !isTokenExpired(refreshToken)) {
    try {
      const response = await axios.post(
       ` ${BASE_URL}api/v1/auth/token/refresh/`,
        { refresh: refreshToken }
      );
      const { access } = response.data.data;
      setTokens(access, refreshToken); // use the existing refresh token
      return access;
    } catch (error) {
      console.error("Token refresh failed:", error);
      removeTokens();
      // Redirect to login or show a message
      // window.location.href = "/login";
      throw error;
    }
  } else {
    removeTokens();
    // Redirect to login or show a message
    // window.location.href = "/login";
  }
};