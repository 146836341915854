import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "./invoice.css";
import * as path from "./../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { BASE_URL } from "../Const/Url";
import { useForm, Controller } from "react-hook-form";
import Loader from "../CustomCommons/Loader";
import swal from "sweetalert";

const EditOutletProduct = () => {
  const history = useHistory();
  const { id } = useParams();
  const [note, setNote] = useState("");
  const [isLoading, SetisLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [existingOutlet, setExistingOutlet] = useState("");
  const [existingOutletProduct, setExistingOutletProduct] = useState(null);
  const [existingOutletVariants, setExistingOutletVariants] = useState([]);
  const [finalOutletVariants, setFinalOutletVariants] = useState(null);
  const [productStock, setProductStock] = useState(0);
  const [variantOptions, setVariantOptions] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      newVariant: [{ label: "", value: "" }],
    },
  });

  useEffect(() => {
    const getProducts = (id) => {
      const url = `${BASE_URL}api/v1/inventory/admin/outlet-product/${id}/`;
      axios.get(url).then((response) => {
        // setMainState(response.data.data.product_details)
        console.log(response.data.data, "exdata");
        const data = response.data.data;
        setExistingOutlet({ label: data.outlet_name, value: data.outlet });
        setExistingOutletProduct(data);
        setProductStock(data.stock);
        setNote(data?.note);
        setExistingOutletVariants(data.outletVariant);
        setFinalOutletVariants(data.outletVariant);
        reset({
          newVariant: data.outletVariant.map((item) => ({
            label: item.variant_details.name,
            value: item.variant,
          })),
        });
        if (data) {
          setLoading(false);
        }
      });
    };
    if (id) {
      getProducts(id);
    }
  }, [id]);

  useEffect(() => {
    const getVariants = (slug) => {
      const url = `${BASE_URL}/api/v1/inventory/inventory/products/${slug}/`;
      axios.get(url).then((response) => {
        console.log(response.data.data, "vardata");
        const formatVariantArray = response.data.data.variant.map((item) => ({
          label: item.name,
          value: item.id,
          ...item,
        }));
        const filteredArray = formatVariantArray.filter(
          (obj1) =>
            !finalOutletVariants.some(
              (obj2) => obj2.variant_details.id === obj1.value
            )
        );

        setVariantOptions(filteredArray);
      });
    };
    if (existingOutletProduct) {
      getVariants(existingOutletProduct.product_details.slug);
    }
  }, [existingOutletProduct, existingOutletVariants]);

  const VariantThead = () => {
    return (
      <thead style={{ border: "1.5px solid #E0E0E0" }}>
        <tr className="px-3" style={{ height: "45px" }}>
          <th
            className="minWidthForInvoiceCreateTable ps-4"
            style={{ width: "349px", textAlign: "start" }}
          >
            Variant
          </th>
          <th
            className="minWidthForInvoiceCreateTable  py-3"
            style={{ width: "150px", textAlign: "center" }}
          >
            Current Main Stock
          </th>

          {/* <th
            className="minWidthForInvoiceCreateTable  py-3"
            style={{ width: "150px", textAlign: "center" }}
          >
            Current Outlet Stock
          </th> */}
          <th
            className="minWidthForInvoiceCreateTable py-3"
            style={{ width: "150px", textAlign: "center" }}
          >
            current Outlet Stock
          </th>

          {/* <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th> */}
        </tr>
      </thead>
    );
  };
  const watchVariantSelect = watch("newVariant");
  useEffect(() => {
    console.log(watchVariantSelect, existingOutletVariants, "www");
    if (watchVariantSelect && dirtyFields.newVariant) {
      console.log("value---", finalOutletVariants, watchVariantSelect);
      const formattedValue = watchVariantSelect
        .filter(({ id }) => id)
        .map((item) => ({
          outlet: existingOutlet.value,
          stock: 0,
          variant: item.id,
          variant_details: item,
        }));
      setFinalOutletVariants([...existingOutletVariants, ...formattedValue]);
    }
  }, [watchVariantSelect]);
 

  const saveProduct = (data) => {
    
    const updatedExistingArray = finalOutletVariants.map((item) => {
      const matchingKey = Object.keys(data).find(
        (key) => key === item.variant.toString()
      );
      if (matchingKey) {
        item.stock = parseInt(data[matchingKey]); // set data object's value
      }
      return item;
    });

    // Filter items where both stock and admin_stock are greater than 0
    const filteredUpdatedArray = updatedExistingArray.filter(
      (item) => item.stock > 0 && item.stock > 0
    );

    const payload = {
      outletVariant: filteredUpdatedArray.map(({ stock, variant }) => ({
        stock,
        admin_stock: stock,
        variant,
        outlet: existingOutlet.value,
      })),
      stock: filteredUpdatedArray.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.stock);
      }, 0),
      admin_stock: filteredUpdatedArray.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.stock);
      }, 0),
      product: existingOutletProduct.product,
      outlet: existingOutlet.value,
      // note:note
    };

    console.log(updatedExistingArray, payload, "save");

    axios
      .patch(
        `${BASE_URL}/api/v1/inventory/admin/outlet-product/${existingOutletProduct.id}/`,
        payload
      )
      .then((res) => {
        if (res.data.status) {
          // clearData();
          showToast("success", "Updated successfully");
          history.push("/outlet/chalan_list");
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/inventory/admin/outlet-product/${id}/`;
          return axios.delete(url);
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            history.push("/outlet/chalan_list");
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {});
      } else {
        swal("Your data is safe!");
      }
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div
        className="invoice-b my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className=" my-2 mb-4 padding-main-head">Outlet </h4>
        <div className="border-p"></div>
        <div className="padding-main">
          <div className="main-select">
            <h1 id={existingOutlet.value}>{existingOutlet.label}</h1>
            {/* <Select
              styles={styles}
              isMulti
              placeholder="---Select---"
              isClearable
              value={existingOutlet}
              isDisabled={true}
              onChange={handleOutletSelect}
              onInputChange={inputProduct}
              options={outlet}
              value={selectedOutlet}
            /> */}
          </div>
          
          <div className="mt-3">
            <div
              className="rounded"
              style={{
                background: "#FAFAFA",
                display: "block",
                overflowX: "hidden",
                width: "100%",
              }}
            ></div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(saveProduct)}>
        <div
          className="invoice-b my-4 bg-white cat-child"
          style={{ borderTop: "0.5px solid #E0E0E0" }}
        >
          <h4 className=" my-2 mb-4 padding-main-head">Product Details</h4>
          <div className="border-p"></div>
          <div className="padding-main">
            <div className="main-select">
              
              <h2>{existingOutletProduct?.product_details.name}</h2>
            </div>

            <div className="mt-3">
              <div
                className="rounded table-responsive"
                style={{
                  background: "#FAFAFA",
                  display: "block",
                  width: "100%",
                  minWidth: "100%",
                  overflowX: "auto",
                  WebkitOverflowScrolling: "touch",
                  position: "relative"
                }}
              >
                <table className="item-tables table_my2" style={{ minWidth: "900px" }}>
                  <tbody>
                    <div
                      className="mt-4 px-2"
                      style={{
                        background: "rgba(245, 245, 245, 0.36)",
                        border: "none",
                      }}
                    >
                      <div className="row py-3 my-3" style={{ minWidth: "900px" }}>
                        <div
                          className="col-2 minWidthForInvoiceCreateTable ps-4"
                          style={{ textAlign: "start" }}
                        >
                          <p>Product Name</p>
                        </div>
                        <div
                          className="col-2 minWidthForInvoiceCreateTable  py-1"
                          style={{ textAlign: "center" }}
                        >
                          Product Main Stock
                        </div>
                        <div
                          className="col-2 minWidthForInvoiceCreateTable  py-1"
                          style={{ textAlign: "center" }}
                        >
                          Current Outlet Product Stock
                        </div>
                        <div
                          className="col-5 minWidthForInvoiceCreateTable  py-1"
                          style={{ textAlign: "center" }}
                        >
                          Variants
                        </div>
                      </div>

                      <div className="row" style={{ minWidth: "900px" }}>
                        <div className="col-2 ps-4 py-1 d-flex align-items-center">
                          {/* <p className="text-capitalize">{curr}</p> */}
                          <img
                            width={50}
                            style={{
                              margin: "6px 0px !important",
                              border: "0.2px solid #bcb0b0",
                            }}
                            height={50}
                            src={
                              !isLoading &&
                              existingOutletProduct?.product_details.thumb_url
                            }
                            alt="failed"
                            className="ms-3 shadow-sm"
                          />
                        </div>
                        <div className="col-2 py-1">
                          <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                            {Number(
                              existingOutletProduct?.product_details.stock
                            )}
                          </p>
                        </div>
                        <div className="col-2 py-1">
                          <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                            {Number(existingOutletProduct?.stock)}
                          </p>
                        </div>
                        <div className="col-5 text-center   py-1 pe-2">
                          <div className="" style={{ marginTop: "-10px" }}>
                            {/* <div className="" style={{ marginTop: "-10px" }}> */}
                            <Controller
                              name="newVariant"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  // defaultValue={field.value}
                                  styles={{
                                    height: "45px",
                                    background: "black",
                                  }}
                                  options={variantOptions}
                                  isMulti
                                  isClearable
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>

                <table className="mapTable shadow-sm ms-2" style={{ minWidth: "900px" }}>
                  <div className="innerTbody w-100" style={{ overflowX: "auto" }}>
                    <VariantThead />
                    <tbody>
                      {finalOutletVariants?.map((variant) => (
                        <tr
                          key={variant.variant}
                          className="border-invoice"
                          style={{ height: "45px" }}
                        >
                          <td className="ps-4 py-3">
                            <p className="text-capitalize">
                              {variant.variant_details?.name}
                            </p>
                          </td>

                          <td className="text-center   py-3">
                            <p>{variant.variant_details.stock}</p>
                          </td>

                          <td>
                            <input
                              type="number"
                              min={0}
                              max={
                                variant.variant_details.stock + variant.stock
                              }
                              defaultValue={variant.stock}
                              {...register(`${variant.variant}`)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          className="padding-btn-head mb-4 d-flex justify-content-end  bg-white cat-child"
          style={{ border: "0.5px solid #E0E0E0" }}
        >
          <div className="" style={{ marginLeft: "-10px" }}>
            <button
              // onClick={saveProduct}
              type="submit"
              className="btn rounded border me-2 mt-2"
            >
              Save
            </button>
            <button
              className="btn rounded border me-2 mt-2"
              onClick={() => history.push(path.outlet_products_list)}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn rounded border me-2 mt-2"
              onClick={() => deleteDataMulti([existingOutletProduct.id])}
              type="button"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditOutletProduct;
