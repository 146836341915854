import Header from "./components/navbar/components/header/Header";
import SideNav from "./components/navbar/SideNav";
import "./App.css";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BASE_URL } from "./components/Const/Url";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
const App = () => {
  
  

  

  const removeDataFromLocalStorage = () => {
    localStorage.removeItem("prodDashCurrentUrl");
    localStorage.removeItem("CatDashQuery");
    localStorage.removeItem("renkingDashCat");
    localStorage.removeItem("catDeshOrdering");
  };
  const removeHomeDecorLocalStorage = () => {
    localStorage.removeItem("homeDecorCurrentUrl");
    localStorage.removeItem("homeDecorQuery");
    localStorage.removeItem("renkingHomeDecor");
  };
  
  const currentPath = window.location.pathname;
  useEffect(() => {
    // Check if the URL is not /product/categories/:id/:id2
    // const handleNavigation = () => {
    const currentPath = window.location.pathname;
    if (!currentPath.startsWith("/product/categories/")) {
      removeDataFromLocalStorage();
      console.log("Calling removeDataFromLocalStorage");
    }
    
    return () => {
      // window.removeEventListener('popstate', handleNavigation);
    };
  }, [currentPath]);
  
  useEffect(() => {
    const currentPath = window.location.pathname;

    // Define a regex pattern for matching paths
    const pathRegex = /^\/inventory\/(edit-product|product-view)\//;
    // Check if the currentPath does not match the pattern
    if (!pathRegex.test(currentPath)) {
      console.log("removeDataFromLocalStorage");
      removeHomeDecorLocalStorage();
      console.log("Calling removeDataFromLocalStorage11");
    }

    // Clean up the event listener when the component unmounts
    return () => {
      // You might want to remove any event listeners or perform cleanup here
    };
}, [currentPath]);

  

  return (
    <>
      <SideNav />
      <ToastContainer  />
    </>
  );
};

export default App;
